import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import Cd from "../../images/overview/cd.inline.svg";
import Ci from "../../images/overview/ci.inline.svg";
import Ce from "../../images/overview/cost.inline.svg";
import Cf from "../../images/overview/flag.inline.svg";

export const introductionCards = [
  {
    id: 1,
    title: "Continuous Delivery",
    image: <Cd width="39px" height="42.15px" />,
    subtitle: "成果を実環境に高速デプロイ",
    content:
      "Harness CDは自律的なCDモジュールであり、エンジニアがスクリプトや依存関係やダウンタイムを気にしなくてもオンデマンドでソフトをデプロイできるようにします。",
    ctaLink: "/CD/",
    ctaName: "CD製品詳細へ",
  },
  {
    id: 2,
    title: "Continuous Integration",
    image: <Ci width="39px" height="39px" />,
    subtitle: "ソースを強力な製品に",
    content:
      "Harness CI（旧Drone）は、コンテナにネイティブ対応する自律的なCIソリューション。すべてのビルドがアイソレートされ、全エクステンションが標準化されています。",
    ctaLink: "/CI/",
    ctaName: "CI製品詳細へ",
  },
  {
    id: 3,
    title: "クラウドコスト管理",
    image: <Ce width="45px" height="31.58px" />,
    subtitle: "クラウドコストの最適化",
    content:
      "アプリ、マイクロサービス、クラスターのクラウドコストの可視性をエンジニアに提供します。一番良いところですか？タグ付けが必要ありません。",
    ctaLink: "/cost/",
    ctaName: "CCM製品詳細へ",
  },
  {
    id: 4,
    title: "Feature Flags",
    image: <Cf width="41px" height="41px" />,
    subtitle: "Feature Flagsの管理",
    content:
      "開発者向けのよりシンプル、高速、柔軟なデリバリー機能。複雑さ、深夜作業、目先の問題への対応に追われていた状況を、デプロイメントのストレスを減らし、より多くの機能と置き換えることができます。",
    ctaLink: "/feature-flags/",
    ctaName: "Feature Flags製品詳細へ",
  },
  {
    id: 5,
    title: "Security Testing Orchestration",
    image: (
      <StaticImage
        src="../../images/home/STOLogo.png"
        layout="fixed"
        width={41}
        height={41}
        alt="Security Testing Orchestration Icon"
      />
    ),
    subtitle: "CI/CDパイプラインのための自動化されたDevSecOps",
    content:
      "安全性の高いアプリケーションサービスを提供しながら、高い速度を維持します。プロアクティブなDevSecOpsアプリケーションセキュリティースキャンとガバナンスを実現します。",
    ctaLink: "/security-testing-orchestration/",
    ctaName: "STO製品詳細へ",
  },
  {
    id: 6,
    title: "Service Reliability Management",
    image: (
      <StaticImage
        src="../../images/home/SRMLogo.png"
        layout="fixed"
        width={41}
        height={41}
        alt="Service Reliability Management Icon"
      />
    ),
    subtitle: "よりよい信頼性でより速くデプロイ",
    content:
      "自動化されたガバナンス、検証、信頼性目標を備えたSLOベースのデリバリー管理により、アプリケーションサービスの信頼性を継続的に向上させます。",
    ctaLink: "/service-reliability-management/",
    ctaName: "SRM製品詳細へ",
  },
  {
    id: 7,
    title: "Chaos Engineering",
    image: (
      <StaticImage
        src="../../images/home/CELogo.png"
        layout="fixed"
        width={41}
        height={41}
        alt="Chaos Engineering Icon"
      />
    ),
    subtitle:
      "アプリケーションの回復力を向上させ、コストのかかるダウンタイムを削減",
    content:
      "制御された「カオス」実験を行い、現実世界のストレスや障害がシステムにどのような影響を与えるかを理解します。より信頼性の高いアプリケーションとインフラストラクチャーを構築できます。",
    ctaLink: "/chaos-engineering/",
    ctaName: "CE製品詳細へ",
  },
];

export const harnessPlatformFacts = [
  {
    id: 1,
    title: "インストール、スケーリング、利用が簡単",
    image: (
      <StaticImage
        src="../../images/easy-install+(1)-1920w.webp"
        width={100}
        height={60}
        alt="Easy to install"
      />
    ),
    content:
      "Harnessモジュールは、SaaSまたはオンプレミスソフトウェアを介して利用できます。GitOpsまたは強力なウィザードを使用して、数分で稼働させることができます。",
  },
  {
    id: 2,
    title: "セルフサービスでデプロイ可能",
    image: (
      <StaticImage
        src="../../images/self-service-deployments+(1)-1920w.webp"
        width={100}
        height={60}
        alt="Self service deployments"
      />
    ),
    content:
      "Harnessを使えばエンジニアは数分またはオンデマンドでパイプラインを構築できます。完璧なロールベースのアクセス制御により組織全体の権限を管理できます。",
  },
  {
    id: 3,
    title: "クラウドネイティブと従来のアプリの双方に対応",
    image: (
      <StaticImage
        src="../../images/cloud-native-1920w.png"
        width={100}
        height={60}
        alt="Cloud native platform"
      />
    ),
    content:
      "Harnessはクラウドネイティブな強力な技術（AMI、コンテナ、サーバレス、Helmチャート）、KubernetesやECSなどのコンテナオーケストレータ、さらにMicrosoft IIS、JBoss、Tomcat、WebSphere、WebLogicなどの従来のデプロイメントを包括サポートします。",
  },
  {
    id: 4,
    title: "RBAC、SSO、および監査証跡",
    image: (
      <StaticImage
        src="../../images/audit-trails-c41742ee-1920w.webp"
        width={100}
        height={60}
        alt="RBAC、SSO、および監査証跡"
      />
    ),
    content:
      "Harnessは、きめ細かいロールベースのアクセス制御、シングルサインオン、包括的な監査機能を備え、現代ビジネスにマッチした設計となっています。誰が・いつ・何を・どこで変更したか、全イベントの監査証跡を把握できます。",
  },
  {
    id: 5,
    title: "GitOps対応",
    image: (
      <StaticImage
        src="../../images/gitops-1920w.png"
        width={100}
        height={60}
        alt="GitOPS"
      />
    ),
    content:
      "YAMLを使って全パイプラインをコードとして宣言およびテンプレート化し、Webhookを使って環境全体のデプロイパイプラインをトリガーします。",
  },
  {
    id: 6,
    title: "クラウドコストとイベントの相関表示",
    image: (
      <StaticImage
        src="../../images/cloud-cost-event-1920w.webp"
        width={100}
        height={60}
        alt="Cloud cost event"
      />
    ),
    content:
      "エンジニアは、クラウドコストをデプロイメント／自動スケーリング／マニフェストの変更と関連付けて、アクションの実際の影響を知ることができます。",
  },
  {
    id: 7,
    title: "カナリア（テスト）デプロイメントの自動化",
    image: (
      <StaticImage
        src="../../images/canary-deployments-1920w.webp"
        width={100}
        height={60}
        alt="Canary delployments"
      />
    ),
    content:
      "HarnessはAI/MLを使用して、カナリアフェーズの性能と品質の低下を自動検出し、必要に応じて、数秒で最後の動作バージョンに自動的にロールバックできます。",
  },
  {
    id: 8,
    title: "一般的なソースコードマネージャーとの統合が可能",
    image: (
      <StaticImage
        src="../../images/any-source-1920w.png"
        width={100}
        height={60}
        alt="Any source"
      />
    ),
    content:
      "Harness CI（旧Drone）は、GitHub、GitHub Enterprise、Bitbucket、GitLabなどの複数のソースコード管理システムとシームレスに統合可能です。",
  },
];
