import React, { useState } from "react";
import Container from "@layout/container";
import HeroBanner from "../../components/common/heroBanner";
import Introduction from "../../components/overview/ci-cd-introduction/Introduction";
import HarnessPlatformIntroduction from "../../components/overview/harness-platform-introduction/HarnessPlatformIntroduction";
import VideoLightBox from "../../components/common/videoLightBox/VideoLightBox";
import Meta from "../../components/seo/Meta";
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

const Overview = (props) => {
  const [isVideoPlaying, toggleVideoPlaying] = useState(false);
  const siteUrl = props?.data?.allSite?.nodes[0].siteMetadata?.siteUrl;
  const metaData = {
    title: `Harnessの概要｜Harness正規販売代理店 DXable`,
    description: `HarnessのCI/CDプラットフォームは、結果の迅速なデプロイとインテグレート、およびクラウドコストの最適化機能を備えたセルフサービス型プラットフォームです。クラウドおよびコンテナと互換性があり、機能フラグの管理と変更の影響の管理をサポートするチーム向けに、SaaS版およびオンプレミス版で提供しています。`,
    url: `${siteUrl}${props.path}`,
  };
  return (
    <>
      <Meta meta={metaData} />
      <Container>
        <HeroBanner
          title={<BannerTitle />}
          paragraph={<BannerParagraph />}
          media={
            <MediaImage
              isVideoPlaying={isVideoPlaying}
              toggleVideoPlaying={toggleVideoPlaying}
            />
          }
        />
        <Introduction />
        <HarnessPlatformIntroduction />
      </Container>
    </>
  );
};

const BannerTitle = () => {
  return (
    <span>
      DevOpsによるDevOpsのためのシンプルでスケーラブルなCI/CDプラットフォーム
    </span>
  );
};

const BannerParagraph = () => {
  return (
    <span>
      HarnessのCI/CDプラットフォームを使うと、ビジネスへのインパクトを抑えて開発を加速できます。
    </span>
  );
};

const MediaImage = ({ isVideoPlaying, toggleVideoPlaying }) => {
  return (
    <>
      <button onClick={() => toggleVideoPlaying(!isVideoPlaying)}>
        <StaticImage
          src="../../images/overview/banner.svg"
          quality={100}
          layout="fullWidth"
          loading="eager"
          alt="Overview Page Panner"
          className="w-full hover:cursor-pointer"
        />
      </button>

      {isVideoPlaying && (
        <VideoLightBox
          src={"https://www.youtube.com/embed/olEMZYfDgOg"}
          title={"Harness Platform In 3 mins"}
          toggleVideoPlaying={toggleVideoPlaying}
          isVideoPlaying={isVideoPlaying}
        />
      )}
    </>
  );
};

export const overviewPageData = graphql`
  query fetchOverviewPageData {
    allSite {
      nodes {
        siteMetadata {
          siteUrl
          assetsBaseUrl
        }
      }
    }
  }
`;

export default Overview;
