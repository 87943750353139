import { Link } from "gatsby";
import React from "react";
import { introductionCards } from "../contents";
import { BsArrowRight } from "react-icons/bs";

const Introduction = () => {
  return (
    <section className="ci-cd-intro">
      <h1 className="ci-cd-intro__title">
        Harness CI/CDプラットフォームのご紹介
      </h1>
      <p className="ci-cd-intro__description">
        Harness
        CI/CDプラットフォームはあらゆるチーム向けのセルフサービスプラットフォームソリューションです。あらゆるタイプのソフトウェアの更新を安全、迅速、かつ持続可能な方法で本番環境にもたらすことができます。
      </p>
      <div className="ci-cd-intro__cards-container">
        {introductionCards.map((card) => (
          <IntroductionCard data={card} key={card.title} />
        ))}
      </div>
    </section>
  );
};

const IntroductionCard = ({ data }) => {
  const { title, subtitle, content, image, ctaLink, ctaName } = data;
  return (
    <div className="ci-cd-intro__cards-container__card">
      <div className="ci-cd-intro__cards-container__card__header">
        <div className="ci-cd-intro__cards-container__card__header__img">
          {image}
        </div>
        <h2 className="ci-cd-intro__cards-container__card__header__title">
          {title}
        </h2>
      </div>
      <h3 className="ci-cd-intro__cards-container__card__subtitle">
        {subtitle}
      </h3>
      <p className="ci-cd-intro__cards-container__card__content">{content}</p>
      {ctaLink && (
        <Link to={ctaLink} className="ci-cd-intro__cards-container__card__cta">
          <p className="ci-cd-intro__cards-container__card__cta__text">
            {ctaName}
          </p>
          <div className="ci-cd-intro__cards-container__card__cta__icon">
            <BsArrowRight size={20} />
          </div>
        </Link>
      )}
    </div>
  );
};

export default Introduction;
