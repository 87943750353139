import React from "react";
import { harnessPlatformFacts } from "../contents";

const HarnessPlatformIntroduction = () => {
  return (
    <section className="platform-introduction">
      <h1 className="platform-introduction__title">
        Harnessのプラットフォームは他とここが違います
      </h1>
      <p className="platform-introduction__description">
        Harness CI/CD
        Platformはクラウドとコンテナにネイティブ対応しており、SaaS版とオンプレミスのソフトウェアが利用できます。
      </p>
      <div className="platform-introduction__facts">
        {harnessPlatformFacts.map((data) => (
          <div className="platform-introduction__facts__item" key={data.id}>
            <div className="platform-introduction__facts__item__image">
              {data.image}
            </div>
            <div className="platform-introduction__facts__item__content">
              <h2 className="platform-introduction__facts__item__content__title">
                {data.title}
              </h2>
              <p className="platform-introduction__facts__item__content__body">
                {data.content}
              </p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default HarnessPlatformIntroduction;
